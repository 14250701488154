@import url(https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css);
#container {
  margin: 1rem;
}
.items {
  margin-bottom: 1rem;
}
ul.pagination{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}
